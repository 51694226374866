import { IMediaFilterFormats, ISortFilter } from 'types/types';

export const GET_FEATURED_NFTS = 'getFeaturedNFTs';

export interface IGetFeaturedNFTsRequestParams {
  chainId: string;
  mediaFormat: IMediaFilterFormats[];
  minPrice: number;
  maxPrice: number;
  sort: ISortFilter;
  shopAddress: {
    chaidId: string;
    shopAddress: string;
  }[];
}