import useQueryFetching from 'hooks/useQueryFetching';
import { INFTDetailResponse } from 'types/nftDetail.types';
import { IReactQueryStatus } from 'types/types';

import { IGetAudioNFTRequestParams } from './interface/getAudioNFT';
import {
  GET_FEATURED_NFTS,
  IGetFeaturedNFTsRequestParams,
} from './interface/getFeaturedNFTs';
import {
  GET_HOME_SLIDER_NFT,
  IGetHomeSliderNFTRequestParams,
} from './interface/getHomeSliderNFTs';
import {
  GET_MARKETPLACE_DETAIL,
  IGetMarketplaceDetailRequestParam,
  IMarketPlaceDetailResponse,
} from './interface/getMarketplaceDetail';
import {
  GET_TOP_COLLECTION,
  IGetTopCollectionRequestParams,
  IGetTopCollectionResponse,
} from './interface/getTopCollection';
import {
  GET_TOP_LIST_CREATORS,
  IGetTopListCreatorsRequestParams,
  IGetTopListCreatorsResponse,
} from './interface/getTopListCreators';
import { IGetVideoNFTRequestParams } from './interface/getVideoNFT';

const useHomePageAPIs = () => {
  const { useFetchData } = useQueryFetching();

  const useGetAudioNFT = ({ size, shopAddress }: IGetAudioNFTRequestParams) => {
    const {
      data,
      status,
    }: { data: { result: INFTDetailResponse[] }; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'getAudioNFT',
        cloudFunctionName: 'getAudioNFT',
        params: { size, shopAddress },
        dependencies: [size, shopAddress],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      });
    return { data: data?.result, status };
  };
  const useGetVideoNFT = ({ size, shopAddress }: IGetVideoNFTRequestParams) => {
    const {
      data,
      status,
    }: { data: INFTDetailResponse[]; status: IReactQueryStatus } = useFetchData(
      {
        variableToStore: 'getVideoNFT',
        cloudFunctionName: 'getVideoNFT',
        params: { size, shopAddress },
        dependencies: [size, shopAddress],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      }
    );
    return { data, status };
  };

  const useGetTopCollection = ({
    shopAddress,
    size,
    day,
  }: IGetTopCollectionRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetTopCollectionResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'TopCollections',
        cloudFunctionName: GET_TOP_COLLECTION,
        params: { shopAddress, size, day },
        dependencies: [size, shopAddress, day],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      });
    return { data, status };
  };

  const useGetTopListCreators = ({
    size,
    subscriptionId,
    shopAddress,
  }: IGetTopListCreatorsRequestParams) => {
    const {
      data,
      status,
    }: { data: IGetTopListCreatorsResponse[]; status: IReactQueryStatus } =
      useFetchData({
        variableToStore: 'TopListCreators',
        cloudFunctionName: GET_TOP_LIST_CREATORS,
        params: { size, subscriptionId, shopAddress },
        dependencies: [size, shopAddress],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      });
    return { data, status };
  };

  const useGetFeaturedNFTs = ({
    chainId,
    mediaFormat,
    minPrice,
    maxPrice,
    sort,
    shopAddress,
  }: IGetFeaturedNFTsRequestParams) => {
    const {
      data,
      status,
    }: { data: INFTDetailResponse[]; status: IReactQueryStatus } = useFetchData(
      {
        variableToStore: 'FeaturedNFTs',
        cloudFunctionName: GET_FEATURED_NFTS,
        params: { chainId, mediaFormat, minPrice, maxPrice, sort, shopAddress },
        dependencies: [
          mediaFormat,
          minPrice,
          maxPrice,
          sort,
          chainId,
          shopAddress,
        ],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      }
    );
    return { data, status };
  };
  const useGetHomeSliderNFTs = ({
    size,
    shopAddress,
  }: IGetHomeSliderNFTRequestParams) => {
    const {
      data,
      status,
    }: { data: INFTDetailResponse[]; status: IReactQueryStatus } = useFetchData(
      {
        variableToStore: 'homeSliderNFTs',
        cloudFunctionName: GET_HOME_SLIDER_NFT,
        params: { size, shopAddress },
        dependencies: [size, shopAddress],
        initialData: [],
        condtionalFetch: shopAddress?.length > 0,
      }
    );
    return { data, status };
  };

  const getMarketPlaceDetail = async ({
    subscriptionId,
  }: IGetMarketplaceDetailRequestParam) => {
    const marketPlaceDetail = await fetch(
      `${process.env.REACT_APP_MORALIS_SERVER_URL}/functions/${GET_MARKETPLACE_DETAIL}`,
      {
        method: 'POST',
        body: JSON.stringify({ subscriptionId }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const detail = await marketPlaceDetail?.json();
    const modifiedResponse: IMarketPlaceDetailResponse = detail?.result;
    return modifiedResponse;
  };

  return {
    useGetAudioNFT,
    useGetVideoNFT,
    useGetTopCollection,
    useGetTopListCreators,
    useGetFeaturedNFTs,
    getMarketPlaceDetail,
    useGetHomeSliderNFTs,
  };
};
export default useHomePageAPIs;
